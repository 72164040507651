@media (width>576px){

    .form-double-col-1{
        display: inline-block;
        width: 50%;
        padding-right: 4px;
    }
    
    .form-double-col-2{
        display: inline-block;
        width: 50%;
        padding-left: 4px;
    }
    
}