.device-sare-row-layout {
  display: flex;
  justify-content: space-between;
  margin-bottom: 340px;
}

@media (max-width: 560px) {
  .device-sare-row-layout {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media (max-width: 560px) {
  .ant-upload,
  .device-sare-upload-btn-container {
    width: 100%;
  }
}

.device-sare-upload-btn {
  margin-left: 15px;
}

@media (max-width: 560px) {
  .device-sare-upload-btn {
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
  }
}
