.custom-meter-id-tooltip .ant-tooltip-content {
  width: fit-content;
}

.meter-id-guidance-photo {
  width: 500px;
  height: 350px;
}

@media (max-width: 850px) {
  .meter-id-guidance-photo {
    width: 400px;
    height: 300px;
  }
}

@media (max-width: 500px) {
  .meter-id-guidance-photo {
    width: 320px;
    height: 300px;
  }
}

@media (max-width: 400px) {
  .meter-id-guidance-photo {
    width: 280px;
    height: 250px;
  }
}
