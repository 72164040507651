.login-card-container {
  height: 90svh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card {
  border: none;

  min-width: 250px;
  width: 48ch;
}

.login-card > .ant-card-body {
  padding: 24px 36px;
}

.error-bar,
.signup-button {
  margin-bottom: 24px;
}

.signup-button {
  margin-top: 24px;
}

.login-button,
.signup-button,
.language-button {
  height: 36px;
  background: var(--theme-dark-blue);
}

.logo-image-div {
  display: flex;
  justify-content: center;
  margin: 36px 0px;
}

.error-bar {
  width: 100%;
  justify-content: center;
  color: red;
}
