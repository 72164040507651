body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f3f3;
  overflow: hidden;
  @media (width< 1031px) {
    overflow: visible;
  }
}

:root {
  --theme-dark-blue: #01063b;
  --title-font-weight: 500;
  --title-font-size: 18px;
}
