/*
  CUSTOMIZE style of menu 
  by overriding antd default
*/
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid rgba(0, 0, 58, 0.6);
}

.ant-menu-item-active,
.ant-menu-submenu-active {
  background-color: rgb(239, 238, 238) !important;
  color: rgba(0, 0, 58, 0.6) !important;
}

.ant-menu-submenu-title:hover {
  color: rgba(0, 0, 58, 0.6) !important;
}

.ant-menu-item-selected,
.ant-menu-submenu-selected,
.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: black !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

#asset-layout .ant-layout-header {
  padding: 0px !important;
}

/* make header a flexbox container*/
#header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  width: 100%;
  padding: 0px min(2%, 50px);
  border-bottom: 1px solid lightgrey;
}

.header-logo {
  height: 20px;
  padding: 0px 10px;
  order: 1;
}
.header-menu {
  order: 2;
  min-width: 0; /* for responsive collapse */
  flex: auto; /* for responsive collapse */
  font-weight: 500;
  width: 60%;
}
.header-select {
  order: 3;
  margin: 0px 10px;
}
.header-logout-button {
  order: 4;
  background: white;
  font-weight: 500;
  height: 100%;
}

/* 
  CUSTOMIZATION for MOBIILE mode
*/
@media (max-width: 576px) {
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: none;
  }

  #asset-layout .ant-layout-header {
  }

  #header-container {
    flex-wrap: wrap;
    padding-bottom: 17px;
  }

  /* 
    move order of selector to be after logout button 
  */
  .header-logo {
    order: 1;
  }
  .header-menu {
    order: 2;
    flex: 0 0 54px; /*fix width so that whole menu is collapsed/hidden*/
  }
  .header-select {
    order: 4;
    width: 100%; /*takes up the entire row*/
    margin: 0;
  }
  .header-logout-button {
    order: 3;
    margin-left: auto; /*place the button on the right-end*/
  }
}

#sider-menu-container {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: white;
  box-shadow: 1px 1px 1px black;
}

/*
  commonly shared style
*/
.context-card {
  height: 98%;
  width: auto;
  margin: 0.5% 0.5% 0.5% 0.5%;
}
