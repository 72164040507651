#error-page {
    height: 92vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.error-text-title {
    font-family: fantasy;
    font-size: 188px;
    font-weight: 500;
    letter-spacing: 5px;
    text-shadow: 4px 4px #00ffc8;
    margin: 0;
    margin-top: -100px;
}

.error-text-page-not-found {
    margin-top: -60px;
    font-family: fantasy;
    font-size: 42px;
    font-weight: 400;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}

.error-text-description {
    margin: auto;
    margin-top: 10px;
    color: #000;
    font-weight: 00;
    width: 70%;
}

.device-not-found {
    font-family: system-ui;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 5px;
    text-shadow: 1px 1px red;
    margin: 0;
    margin-top: -100px;
}