#internal-device-management {
  margin: 10px 10px;
  margin-bottom: 0px;
}

#internal-device-management .ant-card-body {
  padding: 0px;
}

#internal-device-management .organisation-container {
  padding: 12px 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#internal-device-management .organisation-name-selection {
  width: 120px;
  margin-right: 10px;
  margin-bottom: 5px;
}

#internal-device-management .card-body-wording {
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 5px;
}

#internal-device-management .table-action-col-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
}

#internal-device-management .user-details-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
  box-shadow: 0px 0px 5px #e4e4e4;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 110px;
}

#internal-device-management .organisation-details-btn {
  margin-bottom: 5px;
  margin-right: 10px;
}

#internal-device-management .organisation-details-download-btn {
  margin-bottom: 5px;
}

#internal-device-management .title-heading {
  display: flex;
  justify-content: space-between;
}

#internal-device-management .organisation-name-icon {
  display: flex;
  justify-content: space-between;
}
