.signup-card-container {
  height: 90svh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-card {
  border: none;

  min-width: 250px;
  width: 48ch;
}

.signup-card > .ant-card-body {
  padding: 24px 36px;
}

.signup-type-selection-title {
  font-size: var(--title-font-size);
  font-weight: var(--title-font-weight);

  margin: 20px 0;
  text-align: center;
}

.signup-selection-button {
  height: 36px;
  background: var(--theme-dark-blue);

  margin: 20px 0;
}

/*
 Layout guide:
    - https://www.youtube.com/watch?v=c13gpBrnGEw&t=0s
*/

.signup-content-grid {
  display: grid;
  grid-template-columns: 1fr [content-start] min(600px, 80%) [content-end] 1.05fr;
  height: 100vh;
  overflow-y: auto;
  /* background-color: white; */ /* TO-DO: change display to flex and set 100vh maybe */
}

.signup-content-grid > * {
  grid-column: content;
}

.signup-top-margin {
  margin-top: 60px;
}
.signup-bottom-margin {
  margin-bottom: 60px;
}

.signup-step-container {
  margin: 12px 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
.signup-step-nav-container {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.signup-form-container {
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgb(217, 217, 217);
  margin-top: 16px;
}
