#home-page {
  margin: 10px 10px;
  margin-bottom: 0px;
}

#home-page .ant-card-body {
  padding: 0px;
}

#home-page .greeting {
  padding: 12px 12px;
}

#home-page .btn-container {
  padding: 0px 12px;
  padding-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

#home-page .registration-btn {
  width: 150px;
  height: 150px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#home-page .registration-icon {
  font-size: 60px;
}

#home-page .table-action-col-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
}
