#internal-organisation-management {
  margin: 10px 10px;
  margin-bottom: 0px;
}

#internal-organisation-management .ant-card-body {
  padding: 0px;
}

#internal-organisation-management .card-body-wording {
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 5px;
}

#internal-organisation-management .table-action-col-container {
  display: flex;
  flex-wrap: wrap;
}
