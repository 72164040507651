#device-management {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
}

.table-action-col-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
}

#asset-information {
  /* min-height: 330px; */
  /* min-height: "100%"; */
  min-height: 40vh;
}

#i-rec-management {
  /* min-height: 480px; */
  /* max-height: 520px; */
  min-height: 48vh;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  height: 100%;
}

.flexbox-container-row {
  display: flex;
  flex-direction: row;
}

.flexbox-container-col {
  display: flex;
  flex-direction: column;
}

.summary-alignment {
  justify-content: center;
  align-items: center;
}

.summary-box-margin {
  margin: 15px 20px 15px 20px;
}

.ant-upload-wrapper .ant-upload-select {
  width: 100%;
}

.form-item-required {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}

#i-rec-management .ant-card-head {
  padding: 0px 16px;
}

#i-rec-management .ant-card-body {
  padding: 0px;
}

#asset-information .ant-card-head {
  padding: 0px 16px;
}

#asset-information .ant-card-body {
  padding: 0px 16px;
}

.caret-button {
  border: none;
  background: none;
}

.caret-button .anticon {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.caret-button:hover .anticon {
  opacity: 1;
}

#IRecSummary .g2-html-annotation {
  overflow: visible !important;
}
