.ant-layout .ant-layout-has-sider > .ant-layout-content {
  background: white;
}

.device-registration-outer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  background-color: white;
}

.device-registration-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 12px;
  position: relative;
  height: calc(100vh - 64px);
}

@media (max-width: 806px) {
  .device-registration-content-container {
    height: 100%;
  }
}

.device-registration-content-container-row-layout {
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.device-registration-h3-style {
  padding: 5px 0px;
  font-size: 1.2rem;
}

.device-registration-h2-style {
  padding: 5px 0px;
  font-size: 2rem;
}

.device-registration-horizontal-line {
  border-bottom: 1px solid lightgrey;
}

.device-registration-btn-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.device-registration-form-container {
  width: inherit;
}

.device-registration-prev-btn-style {
  color: black;
  border-color: black;
  background-color: white;
  width: 120px;
}

.device-registration-delete-draft-btn-style {
  color: black;
  border-color: black;
  background-color: white;
  width: 120px;
}

.device-registration-save-draft-btn-style {
  color: black;
  border-color: black;
  background-color: white;
  width: 120px;
}

.device-registration-next-btn-style {
  color: white;
  border-color: rgb(10, 5, 129);
  background-color: rgb(13, 7, 129);
  width: 120px;
}

@media (max-width: 548px) {
  .ant-space-item,
  .device-registration-prev-btn-style,
  .device-registration-delete-draft-btn-style,
  .device-registration-save-draft-btn-style,
  .device-registration-next-btn-style {
    width: 100%;
  }
}

.device-registration-long-form-item-style {
  min-width: 330px;
  width: inherit;
}
