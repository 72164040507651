.device-registration-2-row-layout {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

@media (max-width: 560px) {
  .device-registration-2-row-layout {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media (max-width: 560px) {
  .ant-upload,
  .device-registration-2-upload-btn-container,
  .device-registration-2-wording-container {
    width: 100%;
  }
}

.device-registration-2-upload-btn {
  margin-left: 15px;
}

@media (max-width: 560px) {
  .device-registration-2-upload-btn {
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
  }
}

#device-registration-supporting-documents
  .ant-upload-wrapper
  .ant-upload-select {
  display: flex;
  justify-content: flex-end;
}
